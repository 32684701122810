html,
body,
#__next {
    background-color: #f6f7fb;
    color: #212162;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold;
    }
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
}
