@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/hind/Hind-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/hind/Hind-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/hind/Hind-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/hind/Hind-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/hind/Hind-Bold.ttf) format('truetype');
}
